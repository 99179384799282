import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Paper,
  createTheme,
  ThemeProvider,
  Alert,
} from '@mui/material';
import { submitAlphaTestSignup } from '../services/api';
import { AlphaTestSignup } from '../types';

const theme = createTheme({
  palette: {
    primary: {
      main: '#250e38',
    },
    secondary: {
      main: '#250e38',
    },
  },
});

const SignupForm: React.FC = () => {
  const [formData, setFormData] = useState<AlphaTestSignup>({
    fullName: '',
    email: '',
    alphaTesting: false,
    newsletter: false,
  });
  const [errors, setErrors] = useState<Partial<AlphaTestSignup>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{
    type: 'success' | 'error';
    message: string;
  } | null>(null);

  const validateForm = (): boolean => {
    const newErrors: Partial<AlphaTestSignup> = {};
    
    if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full name is required';
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    
    if (!formData.alphaTesting) {
      newErrors.alphaTesting = true;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitStatus(null);
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await submitAlphaTestSignup(formData);
      if (response.success) {
        setSubmitStatus({
          type: 'success',
          message: response.message || 'Thank you for signing up! We will contact you soon.'
        });
        setFormData({
          fullName: '',
          email: '',
          alphaTesting: false,
          newsletter: false,
        });
      } else {
        setSubmitStatus({
          type: 'error',
          message: response.message || 'Something went wrong. Please try again.'
        });
      }
    } catch (error) {
      setSubmitStatus({
        type: 'error',
        message: 'An error occurred. Please try again later.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={3} sx={{ 
        p: { xs: 2, sm: 3, md: 4 },
        width: { xs: '90%', sm: '70%', md: '38.2%' },
        marginLeft: { xs: 'auto', sm: 'auto', md: '30.9%' },
        marginRight: { xs: 'auto', sm: 'auto', md: '30.9%' },
        marginTop: '5.5%',
        mx: 'auto',
        position: 'relative'
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          mb: 3,
          backgroundColor: '#250e38',
          padding: '1.5rem',
          borderRadius: '4px'
        }}>
          <img 
            src="/logo.png" 
            alt="Universale Logo" 
            style={{ 
              height: '60px',
              width: 'auto'
            }} 
          />
        </Box>
        <Typography variant="body1" align="center">
          Join our alpha testing program
        </Typography>
        <Typography variant="body1" paragraph align="center">
          and help shape the future of Universale
        </Typography>

        {submitStatus && (
          <Alert 
            severity={submitStatus.type} 
            sx={{ mb: 2 }}
            onClose={() => setSubmitStatus(null)}
          >
            {submitStatus.message}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              fullWidth
              id="fullName"
              name="fullName"
              label="Full Name"
              value={formData.fullName}
              onChange={handleChange}
              error={!!errors.fullName}
              helperText={errors.fullName}
            />
            
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              required
            />

            <Box>
            <FormControlLabel
                control={
                  <Checkbox
                    name="alphaTesting"
                    checked={formData.alphaTesting}
                    onChange={handleChange}
                  />
                }
                label={
                  <Box>
                    <Typography>Yes, sign me up for Alpha Testing!</Typography>
                  </Box>
                }
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="newsletter"
                    checked={formData.newsletter}
                    onChange={handleChange}
                  />
                }
                label={
                  <Box>
                  <Typography>Subscribe me to the newsletter.</Typography>
                  </Box>
                }
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.25, mt: 0.5 }}>
              <Typography 
                variant="body2" 
                align="center" 
                color="text.secondary"
                sx={{ fontStyle: 'italic' }}
              >
                Early adopter spots are limited—secure yours today!
              </Typography>
              <Typography 
                variant="body2" 
                align="center" 
                color="text.secondary"
                sx={{ opacity: 0.7 }}
              >
                We respect your privacy and never spam.
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting || !formData.email}
              >
                {isSubmitting ? 'Submitting...' : 'Reserve The Spot'}
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>
    </ThemeProvider>
  );
};

export default SignupForm; 